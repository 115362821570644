
import { Vue, Options } from 'vue-class-component'
import { msalClient } from '@/libs'

@Options({ name: 'LoginView' })
export default class LoginView extends Vue {
  public async mounted(): Promise<void> {
    const success: boolean = await msalClient.handleRedirectPromise()
    if (!success) {
      return msalClient.signInRedirect('/login')
    } else if ('redirect' in this.$route.query) {
      const redirect = this.$route.query.redirect as string
      this.$router.push(decodeURIComponent(redirect))
    } else {
      this.$router.push('/home')
    }
  }
}
